$.fn.slider = function() {
    var $this = $(this);
    
    var ul = $("<ul>").addClass('relative');

    $this.children().each(function(){
        $(this).wrap('<li>');
    });

    $this.children().appendTo(ul);
    ul.appendTo($this);
    var prevBTN = $("<span>").addClass('hover absolute fa fa-angle-left').css({
        'font-size': 60,
        top:-200,
        bottom: 0,
        left: 10,
        margin: 'auto',
        'z-index': 10,
        height: 60,
        cursor: 'pointer',
        color: "#ffffff",
    });
    var nextBTN = $("<span>").addClass('hover absolute fa fa-angle-right').css({
        'font-size': 60,
        top:-200,
        bottom: 0,
        right: 10,
        margin: 'auto',
        'z-index': 10,
        height: 60,
        cursor: 'pointer',
        color: "#ffffff",
    });

    $this.append(prevBTN);
    $this.append(nextBTN);

    ul.prepend(ul.children().last().clone().addClass('cloned'));
    ul.append(ul.children().eq(1).clone().addClass('cloned'));

    var current = ul.children().eq(1);

    ul.animate({
        left: - current.position().left,
    }, 0)

    // console.log(current);
    var animate = true;
    prevBTN.click(function(){
        if(!animate) {
            return false;
        }
        animate = false
        clearmtInterval();
        if(current.is('.cloned')) {
            current =  ul.children().last().prev();
            ul.animate({
                left: - current.position().left,
            }, 0)
        }

        current = current.prev();

        ul.animate({
            left: - current.position().left,
        }, 400, function(){
            if(current.is('.cloned')) {
                current =  ul.children().last().prev();
                ul.animate({
                    left: - current.position().left,
                }, 0)
            }
            setmyInterval()

            animate = true
        })

        // console.log('gfh', current.position().left)
    });

    nextBTN.click(function(){
        if(!animate) {
            return false;
        }
        animate = false
        clearmtInterval();
        if(current.is('.cloned')) {
            current =  ul.children().eq(1);
            ul.animate({
                left: - current.position().left,
            }, 0)
        }

        current = current.next();

        ul.animate({
            left: - current.position().left,
        }, 400, function(){
            if(current.is('.cloned')) {
                current =  ul.children().eq(1);
                ul.animate({
                    left: - current.position().left,
                }, 0)
            }

            setmyInterval()
            animate = true
        })

        // console.log('gfh', current.position().left)
    });

    $(window).resize(function(){
        ul.animate({
            left: - current.position().left,
        }, 0)
    })

    var interval = null;

    var setmyInterval = function() {
        interval = setInterval(function(){
            nextBTN.click()
        }, 5000)
    }

    var clearmtInterval = function() {
        clearInterval(interval);
    }

    setmyInterval()
}